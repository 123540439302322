<template>
  <b-collapse class="card">
    <div slot="trigger" slot-scope="props" class="card-header">
      <p class="card-header-title">
        <strong>Delegates</strong>
      </p>

      <a class="card-header-icon has-text-grey-light">
        <b-icon :icon="props.open ? 'minus' : 'plus'" size="is-small" />
      </a>
    </div>

    <div class="card-content">
      <p class="has-margin-bottom-50">
        Grant site access to other users – giving them permission to create new
        tasks, manage assigned tasks, add site secrets and more. For security
        reasons, only site owners can add and remove delegates.
      </p>

      <loading v-if="loading" />

      <delegates-table
        v-else
        :loading="processing"
        :delegates="delegates"
        @addDelegate="addDelegate"
        @removeDelegate="removeDelegate"
      />

      <auto-assign-delegates-to-tasks-field
        v-if="delegatesCount"
        class="has-margin-top-150"
        :site-id="siteId"
        :value="site.autoAssignDelegatesToTasks || false"
      />
    </div>
  </b-collapse>
</template>

<script>
export default {
  name: "SiteDelegates",
  components: {
    "delegates-table": () => import("./_delegatesTable"),
    "auto-assign-delegates-to-tasks-field": () =>
      import("./_autoAssignDelegatesToTasksField")
  },
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      autoAssignDelegatesToTasks: false,
      loading: true,
      processing: false
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    delegates() {
      return (
        this.$store.getters["sites/siteDelegates"]({
          siteId: this.siteId
        }) || {}
      );
    },
    delegatesCount() {
      return this.$_.size(this.delegates);
    }
  },
  created() {
    this.$store
      .dispatch("sites/observeDelegates", { siteId: this.siteId })
      .then(() => {
        this.loading = false;
      });
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveDelegates", { siteId: this.siteId });
  },
  methods: {
    addDelegate() {
      this.$modal.open({
        component: () => import("@shared/sites/_delegateModal"),
        parent: this,
        width: 400,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          siteId: this.siteId
        }
      });
    },
    removeDelegate(delegate) {
      this.$store
        .dispatch("sites/removeDelegate", delegate)
        .then(result => {
          this.$toast.open({
            message: result.message
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error.message}`,
            type: "is-danger"
          });
        });
    }
  }
};
</script>
